/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 26/07/2024 - 12:09:00
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 26/07/2024
    * - Author          : 
    * - Modification    : 
**/
import React, {useState,useEffect,lazy,Suspense} from 'react'
import { useParams } from "react-router-dom";
import './Financier.style.scss';
import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useDispatch, useSelector } from 'react-redux';
import {isEmpty} from "lodash";
import { DASHBOARD } from '../../../../../navigation/CONSTANTS';
import {SOLAR_BATTERY_CONFIGS} from '../../Configuration/config';
import { getprojectDetails,getProjectStatus, getTooltips, setNotesSectionHandler, setOverViewChangesHandler, setProjectDetails, setUpdateProjectDetailsFlag } from "../../../../../store/projectList/projectListSlice";
import { useNavigate } from 'react-router-dom';
import { setMarkAsComplete } from '../../../../../store/systemDesign/systemDesignSlice';
import { isActionAllowed } from '../../../../../utils/helper';
import { Skeleton } from '@mui/material';
import { InfoDialog } from '../../../../../layout/UI/dialog/dialog';
const AccountDetail = lazy(() => import("../AccountDetail"));
const ProjectOverview = lazy(()=>import("../SingleProjectDetail/index"));
const ProjectFiles = lazy(()=> import("../ProjectFile/FilesList"));
const Consumption = lazy(() => import("../../SolarBatteryModule/EnergyProfile"));
const Solutions = lazy(() => import("../../SolarBatteryModule/Solutions"));
const Contracts = lazy(()=> import("../../SolarBatteryModule/Contracts"));
const SoldProject = lazy(()=> import("../../SolarBatteryModule/SoldProjects"));

const EvseQuestionnaire = lazy(() => import('../../Evse/Questionnaire/index'))
const EvseQuotes = lazy(()=>import('../../Evse/quotes/index'))
/**TODO
 * Tabs Configure for solar & battery
 * Import tab content module  -> Overview / Project Files / Energy Profile
 */

function Financier() {
  const systemDesignData = useSelector((store) => store.systemDesign);
  const projectDetail = useSelector((store) => store.projectList);

  const navigate = useNavigate();
  const dispatch = useDispatch();
 const [value, setValue] = useState(0);
 const { id, type } = useParams();
 const [confirmPopup, setConfirmPopup] = useState({
  isVisible: false,
  title: "Alert",
  buttonText: 'Confirm',
  closeButtonText:'Cancel',
  data1: "",
  data2:'',
  update_function: null,
});



 const financierType = (projectDetail?.projectDetails?.partner_project_type === 'Battery')?SOLAR_BATTERY_CONFIGS.BATTERY_TABS:(projectDetail?.projectDetails?.partner_project_type === 'evCharger')?SOLAR_BATTERY_CONFIGS.EVSE_TABS:SOLAR_BATTERY_CONFIGS.SOLAR_TABS 

 useEffect(()=>{
   if(projectDetail.projectStage !== null)
    handleChange('',projectDetail.projectStage);
  // eslint-disable-next-line
 },[projectDetail.projectStage])

 useEffect(() => {   
  dispatch(getprojectDetails(id));
  dispatch(getProjectStatus(id));
  dispatch(getTooltips());
  return () => {
    dispatch(setProjectDetails({}));
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 },[id])
 useEffect(() => {
  if(!isEmpty(projectDetail?.projectDetails)){
    if(financierType.indexOf(type) >= 0){
      setValue(financierType.indexOf(type));
     }else{
       navigate(DASHBOARD.PROJECT_LIST);
     }
  }
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [financierType,projectDetail?.projectDetails]);

 // This useEffect is used to auto route to solutions page when the user clicks on Mark as complete in System Design page
 useEffect(()=>{
  if(systemDesignData.markAsComplete===2){
    dispatch(setMarkAsComplete(0))
    setValue(2)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 },[systemDesignData.markAsComplete]);
 useEffect(() => {
 // Handle beforeunload event for closing/reloading the page
 const handleBeforeUnload = (event) => {
  if (projectDetail.overViewChangesHandler || projectDetail.notesSectionHandler) {
    event.preventDefault();
    event.returnValue = ""; // Required for the confirmation dialog to show up
  }
};


// Add event listeners
window.addEventListener("beforeunload", handleBeforeUnload);

// Clean up event listeners on component unmount
return () => {
  window.removeEventListener("beforeunload", handleBeforeUnload);
};
}, [projectDetail.overViewChangesHandler ,projectDetail.notesSectionHandler]);


  const handleChange = (event, newValue) => {
    if(!projectDetail.overViewChangesHandler && !projectDetail.notesSectionHandler){
      setValue(newValue);
      navigate(`/dashboard/projects/${financierType[newValue]}/${id}`)
      dispatch(setUpdateProjectDetailsFlag(false));
    }else{
      setConfirmPopup({
        ...confirmPopup,
        isVisible:true,
        data1: 'You have made some changes in Overview section',
        data2: 'Are you sure you want to move without saving the changes ?',
        update_function: () => {
          setValue(newValue);
          navigate(`/dashboard/projects/${financierType[newValue]}/${id}`)
          dispatch(setNotesSectionHandler(false));
          dispatch(setOverViewChangesHandler(false));
        }
      })
    }
  };

  const evStatus =  ["Uploaded Photos","Assessment","Project Submitted","Questionnaire Completed"]

  return (
    <div className="main_details_section">
      <>
        <div className="headerImpersonate">
          <Suspense fallback={<></>}>
            <AccountDetail data={projectDetail?.projectDetails}></AccountDetail>
          </Suspense>
        </div>
        {!projectDetail?.projectDetailsLoader ? (
          <div className="financier_component">
            {projectDetail?.projectDetails?.partner_project_type ===
            "evCharger" ? (
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    className="tabs_root"
                    aria-label="basic tabs example"
                  >
                    <Tab className="tab" label="Overview" />
                    <Tab className="tab" label="Project Files" />
                    <Tab className="tab" label="Customer Questionnaire" />
                    {!evStatus.includes(
                      projectDetail?.projectDetails?.project_status
                    ) && <Tab className="tab" label="Quotes" />}
                  </Tabs>
                </Box>
                <div className="tab_panel">
                  <TabPanel value={value} index={0}>
                    <Suspense fallback={<></>}>
                    {isActionAllowed(["hubble-view-project-detail-permission"]) ? (
                      <ProjectOverview></ProjectOverview>
                    ) : (
                      <p
                        style={{ display: "flex", justifyContent: "center",background:'#fff', borderRadius:'4px', height:'300px', alignItems:'center', fontSize:'400',fontFamily:'ActualM'  }}
                      >
                        You are not authorized to view this page.
                      </p>
                    )}
                    </Suspense>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Suspense fallback={<></>}>
                    {isActionAllowed(["'can-view-project-files'"]) ? (
                      <ProjectFiles />
                    ) : (
                      <p
                        style={{ display: "flex", justifyContent: "center",background:'#fff', borderRadius:'4px', height:'300px', alignItems:'center', fontSize:'400',fontFamily:'ActualM'  }}
                      >
                        You are not authorized to view this page.
                      </p>
                    )}
                    </Suspense>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Suspense fallback={<></>}>
                      <EvseQuestionnaire />
                    </Suspense>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <Suspense fallback={<></>}>
                      <EvseQuotes />
                    </Suspense>
                  </TabPanel>
                </div>
              </Box>
            ) : (
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    className="tabs_root"
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab className="tab" label="Overview" />
                    <Tab className="tab" label="Project Files" />
                    <Tab className="tab" label="Energy Profile"/>
                    <Tab
                      className="tab"
                      label="Solutions"
                      disabled={!projectDetail.projectStatus?.energyProfileStatus && !projectDetail.projectStatus?.solutionStatus}
                    />
                    <Tab
                      className="tab"
                      label="Contracts"
                      disabled={!projectDetail?.projectStatus?.contractStatus}
                    />
                    <Tab
                      className="tab"
                      label="Sold Project Details"
                      disabled={!projectDetail?.projectStatus?.soldProjectStatus}
                    />
                  </Tabs>
                </Box>
                <div className="tab_panel">
                  <TabPanel value={value} index={0}>
                    <Suspense fallback={<></>}>
                    {isActionAllowed(["hubble-view-project-detail-permission"]) ? (
                      <ProjectOverview></ProjectOverview>
                    ) : (
                      <p
                        style={{ display: "flex", justifyContent: "center",background:'#fff', borderRadius:'4px', height:'300px', alignItems:'center', fontSize:'400',fontFamily:'ActualM' }}
                      >
                        You are not authorized to view this page.
                      </p>
                    )}
                    </Suspense>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Suspense fallback={<></>}>
                    {isActionAllowed(["can-view-project-files"]) ? (
                      <ProjectFiles />
                    ) : (
                      <p
                        style={{ display: "flex", justifyContent: "center",background:'#fff', borderRadius:'4px', height:'300px', alignItems:'center', fontSize:'400',fontFamily:'ActualM'  }}
                      >
                        You are not authorized to view this page.
                      </p>
                    )}
                    </Suspense>
                  </TabPanel>                    
                  <TabPanel value={value} index={2}>
                    <Suspense fallback={<></>}>
                    {isActionAllowed(["hubble-view-consumption-page"]) ? (
                      <Consumption></Consumption>
                    ) : (
                      <p
                        style={{ display: "flex", justifyContent: "center",background:'#fff', borderRadius:'4px', height:'300px', alignItems:'center', fontSize:'400',fontFamily:'ActualM' }}
                      >
                        You are not authorized to view this page.
                      </p>
                    )}
                    </Suspense>
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={financierType.indexOf("solutions")}
                  >
                    {/* Solutions page here */}
                    <Suspense fallback={<></>}>
                      {(isActionAllowed(["hubble-view-solution-list"])  && (projectDetail.projectStatus?.energyProfileStatus && projectDetail.projectStatus?.solutionStatus)) ? (
                        <div className='p-3' style={{background:'#fff', borderRadius:'4px'}}>
                          <Solutions></Solutions>
                        </div>
                      ) : (
                        <p
                          style={{ display: "flex", justifyContent: "center",background:'#fff', borderRadius:'4px', height:'300px', alignItems:'center', fontSize:'400',fontFamily:'ActualM'  }}
                        >
                          You are not authorized to view this page.
                        </p>
                      )}
                    </Suspense>
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={financierType.indexOf("contracts")}
                  >
                    {/* Contracts page here */}
                    <Suspense fallback={<></>}>
                      {(isActionAllowed(["hubble-view-contract-page"]) && projectDetail?.projectStatus?.contractStatus) ? (
                        <div className='p-3' style={{background:'#fff', borderRadius:'4px'}}>
                           <Contracts></Contracts>
                        </div>
                      ) : (
                        <p
                          style={{ display: "flex", justifyContent: "center",background:'#fff', borderRadius:'4px', height:'300px', alignItems:'center', fontSize:'400',fontFamily:'ActualM'  }}
                        >
                          You are not authorized to view this page.
                        </p>
                      )}
                    </Suspense>
                  </TabPanel>
                  {/* </TabPanel> */}
                  <TabPanel
                    value={value}
                    index={financierType.indexOf("sold-project")}
                  >
                    {/* Contracts page here */}
                    <Suspense fallback={<></>}>
                      {(isActionAllowed([
                        "hubble-view-sold-project-detail-page",
                      ])  && projectDetail?.projectStatus?.soldProjectStatus) ? (
                        <div className='p-3' style={{background:'#fff', borderRadius:'4px'}}>
                          <SoldProject></SoldProject>
                        </div>                        
                      ) : (
                        <p
                          style={{ display: "flex", justifyContent: "center",background:'#fff', borderRadius:'4px', height:'300px', alignItems:'center', fontSize:'400',fontFamily:'ActualM'  }}
                        >
                          You are not authorized to view this page.
                        </p>
                      )}
                    </Suspense>
                  </TabPanel>
                </div>
              </Box>
            )}
          </div>
        ) : (
          <div className="financier_component">
            <div style={{ display: "flex", padding: "0px 16px", gap: "16px" }}>
              <Skeleton animation="wave" height={48} width={224} />
              <Skeleton animation="wave" height={48} width={224} />
              <Skeleton animation="wave" height={48} width={224} />
              <Skeleton animation="wave" height={48} width={224} />
              <Skeleton animation="wave" height={48} width={224} />
            </div>
            <Skeleton
              variant="rounded"
              animation="wave"
              height={720}
              width={"100%"}
            />
          </div>
        )}
      </>
      <InfoDialog
        maxWidth="sm"
        show={confirmPopup.isVisible}
        version="second"
        handleClose={() => {
          setConfirmPopup({
            ...confirmPopup,
            isVisible: !confirmPopup.isVisible,
          });
        }}
        closeBtnStyle={`button-var5_close`}
        closeButtonText={confirmPopup.closeButtonText}
        buttonText={confirmPopup.buttonText}
        saveChanges={() => {
          confirmPopup.update_function();
          setConfirmPopup({
            ...confirmPopup,
            isVisible: !confirmPopup.isVisible,
          })
        }}
        title={confirmPopup.title}
      ><div className="p-2">
        <p style={{ color: "#121212", fontFamily: "ActualM" }}>
          {confirmPopup.data1}
        </p>
        {confirmPopup.data2.length > 0 && (
        <li>{confirmPopup.data2}</li>
        )}
        </div>
      </InfoDialog>
    </div>
  );
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

export default Financier